export const en = {

    "home.title": "Welcome to the LooC-CMS, you're in the brand: {{brand}}",
    "home.platform.manager.description": "This is a platform, where you can manage users & brands, but you can not upload frames or orders.",
    "home.defaultbrand.manager.description": "Here you can manage your users, frames and orders.",
    "home.defaultbrand.user.description": "As email {{username}} you are a registered user.",
    "home.brandMembership.title": "You are registed under the following brands:",
    "home.brandMembership.switch": "Choose",

    "navigation.home": "Home",
    "navigation.user": "User",
    "navigation.users": "Users",
    "navigation.stores": "Stores",
    "navigation.devices": "Devices",
    "navigation.orders": "Orders",
    "navigation.frames": "Frames",
    "navigation.appconfig": "App Config",
    "navigation.renderings": "Renderings",
    "navigation.builds": "Builds",
    "navigation.materials": "Materials",
    "navigation.logout": "Sign out",
    "navigation.cost": "Cost Explorer",

    "appconfig.title": "Editing the test-app-config of <0>{{brand}}</0>:",
    "appconfig.stage.prod": "Production",
    "appconfig.stage.test": "Testing",
    "appconfig.empty": "Not set.",
    "appconfig.createNew": "Create New",
    "appconfig.edit": "Edit",
    "appconfig.copy": "Copy",
    "appconfig.publish.confirm": "Are you sure you want to use the current Test configuration for all App Store users? Make sure you have tested in the app before publishing.",
    "appconfig.texts.collectionHeader": "Collections Header Text:",
    "appconfig.texts.shoppingDisclaimer": "Shopping card title text",
    "appconfig.texts.shoppingDisclaimerSpecialSize": "Shopping card title text special size",
    "appconfig.texts.customPlasticMaterialName": "Custom Plasticcategorie Title",
    "appconfig.updateInfo.title": "Updateinfo:",
    "appconfig.updateInfo.currentVersion": "Version",
    "appconfig.updateInfo.currentBuildNr.apple": "🍏 - Buildnr.",
    "appconfig.updateInfo.currentBuildNr.android": "🤖 - Buildnr.",
    "appconfig.updateInfo.downloadLink": "Downloadlink",
    "appconfig.updateInfo.contactEmail": "Contact Email",
    "appconfig.updateInfo.facedataEmail": "Email for Face Measurement Results",
    "appconfig.headerImage.title": "Category Menu Header:",
    "appconfig.promoImage.title": "Promo Photo:",
    "appconfig.promoPhoto.tooltip": "Photo is shown next to your brand, e.g. in the platform menu. Ideally {{SIZE}}px.",
    "appconfig.tags.foregroundcolor": "Text Color",
    "appconfig.tags.backgroundcolor": "Background Color",
    "appconfig.other": "Other",

    "statistics.hits": "Count",
    "statistics.diskusage": "CMS disk usage: {{mb}} MB (as of {{date}})",
    "statistics.dayAxis.title": "Days",
    "statistics.legend.mapHits": "Map requests",
    "statistics.legend.AppCheckins": "App check-Ins",
    "statistics.legend.AppCheckins.monthtotal": "app-starts: {{SUM}}",
    "statistics.legend.Orders": "Orders",
    "statistics.legend.WebVTO": "WebGL Sessions",
    "statistics.legend.WebVTO.monthtotal": "{{DAYS}} day total: {{SUM}}",
    "statistics.dailyDetails": "On {{date}} the app contents where updated {{appData}} times, {{mapHits}} map requests were delivered and the system received {{orders}} orders.",
    "statistics.dailyDetails.appVersions.title": "Installed app versions:",
    "statistics.dailyDetails.osVersions.title": "iOS versions:",
    "statistics.nodata": "No app usage data available for {{brand}} yet.",

    "categories.unauthorized": "You are not authorized to access the categories of <0>{{brand}}</0>!",
    "categories.title": "Categories of <0>{{brand}}</0>:",
    "categories.createNew": "Create new Category",
    "categories.refreshNow": "Refresh the Server-Cache now.",
    "categories.refreshNow.text": "Triggers an invalidation call for the Server-Cache. This operation costs money when done too often, so please use responsibly.",
    "categories.refreshNow.confirmationText": "Are you sure all iPhones and iPads with the {{brand}} app should prompt the user to update their models the next time they are opened? Please don't press this button to often, to not overwhelm your users.",

    "category.image": "Thumbnail",
    "category.title": "Title",
    "category.details": "Details",
    "category.status": "State",
    "category.promoted": "Promoted",
    "category.promoted.yes": "Yes",
    "category.promoted.no": "No",
    "category.button.promote": "Promote",
    "category.button.unpromote": "Remove",
    "category.actions": "Actions",
    "category.showModels": "Models",
    "category.button.startTesting": "Test",
    "category.button.publish": "Publish",
    "category.button.unpublish": "Unpublish",
    "category.state.unpublished": "Unpublished",
    "category.state.testing": "Testing",
    "category.state.published": "Published",

    "categorytable.tooltip.editMaterials": "Edit Collection details",
    "categorytable.tooltip.edit": "Set Materials for whole collection.",
    "categorytable.materialsetting.title": "Material settings for <0>{{CATEGORY}}</0>",
    "categorytable.materialsetting.description": "Attention: Material settings will be overwritten for all frames in this collection.",
    "categorytable.materialsetting.overwrite": "Override settings",

    "categorycreate.notauthorized": "You are not authorized to add categories to <0>{{brand}}</0>!",
    "cartegoryedit.title": "Changing properties of category <0>{{category}}</0>",
    "categorycreate.title": "New Category for <0>{{brand}}</0>:",
    "categorycreate.categoryname.label": "Identifier",
    "categorycreate.categorytitel.label": "Titel",
    "categorycreate.categorydetail.label": "Detail",
    "categorycreate.newlanguage.buttontext": "Add Language",
    "categorycreate.newlanguage.prompt": "Please enter the language code of the new language:",
    "categorycreate.form.required": "Required",
    "categorycreate.localizations.language": "Lang.",
    "categorycreate.localizedTitle.title": "Localized Titles:",
    "categorycreate.localizedDetails.title": "Localized Details:",
    "categorycreate.warning.nameMissingOrShort": "Name should be at least 3 characters.",
    "categorycreate.warning.nameInvalidCharacters": "Name should only contain alphanumeric characters.",
    "categorycreate.settings": "Other Settings",

    "categorymodeltable.title": "List of models in <0>{{category}}</0>:",

    "cookiePopup.text": "Can we store a small bit of data on your device to remember your preferences?",
    "cookiePopup.yes": "Remember me",
    "cookiePopup.no": "No Cookies",

    "modeltable.sizes": "Sizes:",
    "modeltable.status": "Status",
    "model.image": "Thumbnail",
    "model.title": "Title",
    "model.actions": "Actions",
    "model.status.transcoding": "Transcoding",
    "model.status.unfinished": "Unfinished",

    "renderings.title": "Renderings for ",
    "RenderingRequest.Scene": "Blender scene:",
    "RenderingRequest.Environment": "HDR image:",
    "RenderingRequest.EstimatedCosts": "Estimated Costs: {{cost}}",
    "RenderingRequest.FreePreview": "Free Preview",
    "RenderingRequest.Urgent": "Urgent",
    "RenderingRequest.ForceFree": "Set Cost-Free",
    "RenderingRequest.Type.usdz": "AR Quick Look",
    "RenderingRequest.Type.gltf": "ModelViewer",
    "rendering.categoryAndModel": "Model",
    "rendering.dateCreated": "Requested",
    "rendering.duration": "Duration",
    "rendering.cost": "Cost",
    "rendering.status": "State",
    "rendering.result": "Result",
    "rendering.tooltip.open": "Open in Browser",
    "rendering.tooltip.copyToClipBoard": "Copy to clipboard",
    "rendering.tooltip.logs": "Debug logs of render process",
    "rendering.tooltip.reloadParameters": "Load same rendering parameters",
    "rendering.tooltip.delete": "Delete",
    "rendering.tooltip.error": "An error occured during the rendering job.",
    "rendering.tooltip.waiting": "The job is waiting for a free machine to execute on.",

    "renderings.nodeselector.none": "none",
    "renderings.nodeselector.title": "Same Material as",
    "renderings.requestNew": "Request new Rendering",
    "renderings.batch": "Batch request",
    "renderings.clearcache": "Clear Cache",
    "renderings.linkTo": "To Renderings",
    "renderings.delete.button": "Delete {{count}}",
    "renderings.deletion.confirm": "Are you sure you want to delete \"{{model}}\"",
    "renderings.monthlyCost": "Costs {{month}}: {{cost}}",
    "rendering.modelviewer.norenderingerror": "Bitte klicken Sie `Zurück` um das Modell neu zu öffnen.",
    "renderingtype.picker": "Type",
    "receipts.rendering": "{{type}} rendering ({{res}})",
    "receipts.renderingcosts.title": "Renderings",
    "receipts.monthlyCosts": "Monthly Usage",
    "receipts.monthlyCosts.costingStores": "Amount of stores: {{stores}}",
    "receipts.monthlyCosts.details.nonVTO": "Non-VTO Stores: {{nonVTO}}",
    "receipts.monthlyCosts.details.freeVTO": "Free Countries: {{freeVTO}}",
    "receipts.monthlyCosts.details.vtoPaying": "Stores with VTO: {{vtoPaying}}",
    "receipts.monthlyCosts.details.orders": "Total orders: {{orders}}",
    "receipts.monthlyCosts.details.appCheckins": "App-Starts: {{total}}, of those free: {{free}}, charged: {{charged}}",
    "receipts.monthlyCosts.details.webVTO": "WebVTO: {{total}}, of those free: {{free}}, charged: {{charged}}",

    "RenderingRequest.Title": "New Rendering for {{brand}}",
    "RenderingRequest.Type.image": "Image",
    "RenderingRequest.Type.video": "Video",
    "RenderingRequest.Denoise": "Denoise",
    "RenderingRequest.Denoise.ToolTip": "Denoising can improve the quality of smooth surfaces.",
    "RenderingRequest.DOF": "Depth of Field",
    "RenderingRequest.DOF.ToolTip": "Depth of Field means the front of glasses will be in focus while objects behind (like the temples) will get slightly blurry.",
    "RenderingRequest.Samples": "Samples",
    "RenderingRequest.Samples.ToolTip": "More samples lead to higher quality but will use more calculation resources.",
    "RenderingRequest.Angle": "Angle",
    "RenderingRequest.Brightness": "Brightness",
    "RenderingRequest.Type": "Type",
    "RenderingRequest.Resolution": "Resolution",
    "RenderingRequest.Request.button": "Request Rendering",
    "RenderingRequest.Request.confirm": "Are you sure you want to request a {{item}} at the price of {{price}}? The incurred costs can not be reversed.",
    "RenderingRequest.Request.Status.Uploading": "Sending request to server",
    "RenderingRequest.Request.Status.DownloadingGlasses": "Downloading 3D Model",
    "RenderingRequest.Request.MaterialTable.Header.Node": "Node",
    "RenderingRequest.material.option.none": "Not selected",
    "RenderingRequest.Request.MaterialTable.Header.Material": "Material",

    "BatchRenderingRequest.Title": "USDZ/GLTF Batch-Request",
    "BatchRenderingRequest.Models.Title": "Models:",
    "BatchRenderingRequest.Materials.Title": "Materials:",
    "BatchRenderingRequest.3DModelsNeedRefetching.Warning": "Please download 3D models again.",
    "BatchRenderingRequest.SpecialNodes.Title": "Special/Extra Nodematerials:",
    "BatchRenderingRequest.SpecialNode.Subtitle.Title": "Node {{name}} appears {{count}} times in chosen models:",
    "BatchRenderingRequest.Materials.Totals": "{{totalCount}} GLTF&USDZ for {{models}} model combinations in {{materialcombinations}} color combinations cost {{price}}",
    "BatchRenderingRequest.Prompt": "Are you sure you want to request {{totalCount}} files for a the price of {{price}}? The incurred costs can not be reversed.",
    "BatchRenderingRequest.Request.Button": "Request 3D Files",
    "BatchRenderingRequest.FetchAndAnalyzeModels.Button": "Fetch&Analyze 3D Models for extra nodes",
    "BatchRenderingRequest.UploadStatus": "{{percent}} Requesting `{{model}}` in `{{metal}}` and `{{plastic}}`",

    "categoryPicker.label": "Category",
    "categoryPicker.all": "all",

    "modelPicker.label": "Model",
    "modelPicker.all": "all",

    "time.ago": "{{time}} ago",

    "categoryContent.addNewModels.Button": "Add new 3D frame",

    "role.assigned": "You are <0>{{role}}</0> for the brand <2>{{brand}}</2>",

    "userlist.unauthorized": "You are not authorized to access users of <0>{{brand}}</0>!",
    "userlist.title": "Users of <0>{{brand}}</0>:",
    "userlist.invite": "New User",
    "userlist.add": "Copy User",
    "userlist.copy.users": "Copy {{count}} users",
    "userlist.rebuild_stores": "Recalculate store geolocation",
    "userlist.rebuild_stores.confirm": "Are you sure you want to run the extensive calculation of gps coordinates of stores now? Please only do this once per day.",
    "userlist.option.showLastused": "Load when app last used",

    "usercreate.notauthorized": "You are not authorized to add users to <0>{{brand}}</0>!",

    "useredit.title": "Editing user <0>{{user}}</0>",
    "usercreate.title": "Setup a new user of <0>{{brand}}</0>:",
    "usercreate.form.email": "Email",
    "usercreate.form.company": "Company of the customer",
    "usercreate.form.first-name": "First Name",
    "usercreate.form.last-name": "Last Name",
    "usercreate.form.address": "Address",
    "usercreate.form.zip-code": "Zipcode",
    "usercreate.form.city": "City",
    "usercreate.form.country": "Country",
    "usercreate.form.region": "Region",
    "usercreate.form.access-level": "Access Level",
    "usercreate.form.max-devices": "Max Devices",
    "usercreate.form.web": "Website",
    "usercreate.form.telNr": "Tel. No.",
    "usercreate.form.telnrlength": "Telephone number too short",
    "usercreate.form.mailCC": "CC mails to",
    "usercreate.form.customerId": "Customer Id",
    "usercreate.form.invalidEmail": "Invalid email address",
    "usercreate.form.required": "Required",
    "usercreate.form.role-store": "Store",
    "usercreate.form.role-manager": "Manager",
    "usercreate.stores": "Stores:",

    "usercreate.brandmembership": "Brand membership:",
    "usercreate.brandmembership.nomember": "Not Member",
    "useredit.legacybrand": "Legacy customer: {{BRAND}}",

    "devicelist.unregistered": "You have no registered devices for this brand yet.",
    "devicelist.title": "These are your registered devices:",
    "devicelist.model": "Model",
    "devicelist.device-id": "Device-Id",
    "devicelist.last-used": "Last used",
    "devicelist.name": "Name",

    "orderlist.no-order-from-brand": "You have not ordered anything from {{brand}} yet.",
    "orderlist.no-order-for-brand": "There are no orders for {{brand}} yet.",
    "orderlist.your-orders-from-brand": "These are your orders from {{brand}} so far:",
    "orderlist.orders-for-brand": "These are all orders for {{brand}}:",
    "orderlist.date": "Order date",
    "orderlist.store": "Store",
    "orderlist.user": "App-Nutzer",
    "orderlist.contact": "Contact Person",
    "orderlist.details": "Frames",
    "orderlist.more": "Load more",

    "storelist.manager.title": "Here you can adjust the stores that are not app users, but still carry {{brand}} products.",
    "storelist.manager.reminder": "If you want to adjust the stores of opticians who use the VTO app, do that via the users-list. ⚠️",
    "storelist.optician.title": "Here you can adjust your own ({{company}}) stores:",
    "storelist.new": "New",

    "orderDetails.title": "Order from {{date}} by {{store}}:",
    "orderDetails.table.header.model": "Model",
    "orderDetails.table.header.special": "Special Size",
    "orderDetails.table.header.details": "Details",
    "orderDetails.table.header.engraving": "Engraving",
    "orderDetails.table.header.comment": "Comment",

    "OrderOption.Frame": "Material",
    "OrderOption.MetalColor": "Metal Color",
    "OrderOption.FrontMetalColor": "Front Color",
    "OrderOption.TempleColor": "Temple Color",
    "OrderOption.LensType": "Lense Type",
    "OrderOption.OverallSize": "Size",
    "OrderOption.BridgeWidth": "Bridge Width",
    "OrderOption.GlasWidth": "Glass Width",
    "OrderOption.GlasHeight": "Glas Height",
    "OrderOption.TempleLength": "Temple Length",
    "OrderOption.PadType": "Pads",

    "detail_item_default_size": "Default ({{SIZE}})",
    "detail_item_special_size": "Special size ({{SIZE}})",

    "frameupload.general.filesize": "Filesize: ",
    "frameupload.tab.general": "General",
    "frameupload.tab.sizes": "Sizes",
    "frameupload.tab.colors": "Colors&Materials",
    "frameupload.tab.files": "Other Files",
    "frameupload.title": "New Model in <0>{{CATEGORY}}<0>",
    "frameupload.editTitle": "Editing model <0>{{MODEL}}<0>",
    "frameupload.fileUpload": "Drag 'n' drop 3D Model here, or click to select file.",
    "frameupload.modelIsResizable": "Model sizes are customizable:",
    "frameupload.unknownFileType": "Unknown file type \"{{FILEEXTENSION}}\".\nTry with Collada *.dae.",
    "frameupload.errorImportingFile": "Error during importing of file:\n{{ERROR}}",
    "frameupload.referenceGlassesFrame": "Show reference glasses.",
    "frameupload.checkUVs": "Check UV's.",
    "frameupload.noWarnings": "Model checked successfully!",
    "frameupload.templeParameter": "Temple parameter: {{PARAMETER}}",
    "frameupload.notUnlocked": "Uploading of 3D models is not yet available for {{BRAND}}.",
    "frameupload.thumbImageUpload": "Thumbnail Image (min. {{SIZE}}, higher resolution is even better)",
    "frameupload.identifier": "Identifier",
    "frameupload.tags": "Tags:",
    "frameupload.collection": "Collection",
    "frameupload.thumbnailTitle": "Thumnail Image",
    "frameupload.allowsLensCustomization": "Adjustable Lenses",
    "frameupload.allowsPlasticCustomization": "Adjustable Plastic",
    "frameupload.allowsMetalCustomization": "Adjustable Metal",
    "frameupload.allowsExtraMaterialCustomization": "Adjustable Extramat.",
    "frameupload.allowsSeparateTempleColor": "Temple-color adjustable",
    "frameupload.restrictedLenses": "Restrict Lenses",
    "frameupload.restrictedLenses.table.title": "Choosable Lenses:",
    "frameupload.restrictedPlastics": "Restrict Plastics",
    "frameupload.restrictedPlastics.table.title": "Choosable Plastics:",
    "frameupload.fixedNodes.table.title": "Fixed Nodes:",
    "frameupload.restrictedMetals": "Restrict Metals",
    "frameupload.restrictedMetals.table.title": "Choosable Metals:",
    "frameupload.restrictedExtraMats": "Restrict Extramat.",
    "frameupload.restrictedExtraMats.table.title": "Choosable Extramat.:",
    "frameupload.shadedByDefault": "Shaded Glass",
    "frameupload.templesUsePadMaterial": "Transparent Temple",
    "frameupload.header.defaultSizes": "Default sizes (mm):",
    "frameupload.size.defaultBridgeSize": "Bridge Size",
    "frameupload.size.defaultGlasWidth": "Lens width",
    "frameupload.size.defaultGlasHeight": "Lens height",
    "frameupload.size.defaultTempleLength": "Temple length",
    "frameupload.header.testSizeChange": "Test size changing:",
    "frameupload.upload.button": "Create&Upload",
    "frameupload.uploadAndChange.button": "Save&Upload Changes",
    "frameupload.option.textureTransformScale": "TexCoord Scale",
    "frameupload.option.minimumAppVersion": "Min. Buildnr.",
    "frameupload.fixedsizes.allowFixedSizeSteps": "Multiple Sizes",
    "frameupload.localizedname.title": "Local. Name",
    "frameupload.localizedname.label": "Name",
    "frameupload.status.dxfSevereErrorCount": "Please fix {{ERRORS}} errors in the DXF file before you can submit the changes.",
    "frameupload.status.frameAnalysis": "Analyzing 3D Model",
    "frameupload.status.dxfAnalysis": "Analyzing DXF file",
    "frameupload.status.createDBEntry": "Create Database Entry",
    "frameupload.status.UploadingThumbnail": "Uploading Thumbnail",
    "frameupload.status.UploadingModel": "Uploading Model",
    "frameupload.status.UploadingDXF": "Uploading 2D schematic",
    "frameupload.status.DownloadingModel": "Downloading 3D Model",
    "frameupload.status.DownloadingDXF": "Downloading 2D schematic",
    "frameupload.fixedsizes.name.header": "Designation",
    "frameupload.fixedsizes.sizeFactor.header": "Scale",
    "frameupload.fixedsizes.name.label": "Name",
    "frameupload.fixedsizes.sizeFactor.label": "Scale",
    "frameupload.fixedsizes.addSize.button": "Add Size",
    "frameupload.fixedsizes.addSize.prompt": "Designation for the new size:",
    "frameupload.fixedBridgeSizes.title": "Fixed bridge sizes",
    "frameupload.fixedBridgeSizes.header": "Size:",
    "frameupload.fixedBridgeSizes.addSize.button": "+ Size",
    "frameupload.dragInFileReminder": "Just drag new files anywhere on this screen.",
    "frameupload.modelheadNode": "Head 3D model",

    "frameupload.coloroptions.lens": "Default Lens",
    "frameupload.coloroptions.metal": "Default Metal",
    "frameupload.coloroptions.plastic": "Default Plastic",
    "frameupload.coloroptions.separateTempleColor": "Sep. Templemat.",
    "frameupload.coloroptions.extraMaterial": "Material {{LETTER}}",
    "frameupload.coloroptions.fixedNode.addNewRow": "Add fixed Node Material",

    "frameupload.warning.nodeMissing": "Object {{NODE}} is missing, expected this node to be present.",
    "frameupload.warning.faultyNodeName": "Objekt {{NODE_NAME}} has an unexpected symbol in its name \"{{WRONG_NAME}}\" which can lead to problems.",
    "frameupload.warning.siblingNode": "Unexpected 3D Object {{NODE}} found next to Glasses.",
    "frameupload.warning.glassesNodeMissing": "There should be a single \"Glasses\" node at the root of the file containing all pieces of the frame.",
    "frameupload.warning.lensNodesMissing": "This model is missing the \"LENSES\" objectnode or the LENS_RIGHT + LENS_LEFT if it's supposed to be resizable.",
    "frameupload.warning.extraNode": "Found unexpected node named {{NODE}}",
    "frameupload.warning.extraUnnamedNode": "Found unexpected nameless node, probably something to be fixed.",
    "frameupload.warning.extraNodeInTwoCategories": "Extra node letter \"{{LETTER}}\" occurs both in {{MATTYPE1}} as well as {{MATTYPE2}} materials. Please change in one type to a different letter.",
    "frameupload.warning.duplicatedNode": "{{NODE}} is duplicated.",
    "frameupload.warning.degenerateMatrix": "{{NODE}}'s rotation or scale isn't valid, maybe this object should be deleted.",
    "frameupload.warning.scaledNode": "{{NODE}}'s is scaled, it should equally have a scale of (1,1,1).",
    "frameupload.warning.rotatedNode": "{{NODE}}'s is rotated, please change the angles to zero.",
    "frameupload.warning.nodesShouldCoincide": "{{NODEA}} should be at the same position as {{NODEB}}'s coordinate center.",
    "frameupload.warning.multipleGeometries": "Child {{CHILD}} of {{NODENAME}} has a geometry with {{COUNT}} parts, this is often caused by putting separate objects into the same node. E.g. by accidentally putting metal parts into FRONT_LEFT/RIGHT that should be in their own FRAME_LEFT/RIGHT.",
    "frameupload.warning.fileSize": "Model file is larger then {{MAXSIZE}}MB, to reduce app loading time it is recommended to stay under that.",

    "frameupload.dxfwarning.WarningTitle": "There are the following problems in the dxf file provided:",
    "frameupload.dxfwarning.missingColor": "An objekt of type '{{TYPE}}' had no color, please check whether there might be a text or line with 'Color by layer'.",
    "frameupload.dxfwarning.duplicate": "The provided dxf file contained {{DUPLICATES}} all with the same color. Each object should have its own annotation and its own color.",
    "frameupload.dxfwarning.missingAnnotation": "The provided dxf file is missing an annotation for '{{NAME}}'.",
    "frameupload.dxfwarning.missingCurve": "The provided dxf file has no curves with the same color for '{{NAME}}'.",
    "frameupload.dxfwarning.partsMissing": "So far only glasses that have a shape, bridge (and pad) can be combined into a full frame!",
    "frameupload.dxfwarning.noLineConnects1And2": "{{PART1}} and {{PART2}} should overlap in exactlty one line, in which they will be combined together.",
    "frameupload.dxfwarning.missingConnection": "No common line between parts could be found, therefore not merging the components into a full frame.",
    "frameupload.dxfwarning.unexpectedLineCountConnectingPadAndShape": "Pad and Shape should overlap in exactlty one line, but they did have {{COUNT}} in common.",
    "frameupload.thumbnailtobig.alert": "Das gewählte Bild ist {{SIZE}}kb groß, bitte wählen Sie ein Bild mit einer Größe von maximal {{MAXIMUMSIZE}}kb.",

    "frameupload.dxf.uploadForQualityDXF": "To create a size-adjusted dxf, the original dxf has to be uploaded to the cloud to be analyzed.",
    "frameupload.dxf.fileConversionOngoing": "The original dxf is being converted in the cloud, please reload this page in a few minutes.",

    "frameupload.DXFViewer.mergeHinge": "Combine Hinge",
    "frameupload.DXFViewer.mergePad": "Combine Pads",
    "frameupload.DXFViewer.Input": "In",
    "frameupload.DXFViewer.Output": "Out",

    "frameupload.purchaseLinks.title": "Link to Website",
    "frameupload.purchaseLinks.label": "Link",
    "frameupload.purchaseLinks.newRow": "Add CountryCode",
    "frameupload.purchaseLinks.new.prompt": "Please enter the region code of the store you would like to add",

    "frameupload.tags.new.prompt": "Please enter the new tag you want to use",
    "frameupload.addTag.Button": "Add Tag",
    "frameupload.tags.newOption": "✚ create",
    "frameupload.info.lastEdited": "Last edited {{DATE}} by {{USER}}.",

    "frameupload.nodeinfo.isSplit": "Model is left/right split so users can adjust shape:",
    "frameupload.nodeinfo.materialTooltip.metal": "Uses metal material or color",
    "frameupload.nodeinfo.materialTooltip.glas": "Uses glas material or color",
    "frameupload.nodeinfo.materialTooltip.plastic": "Uses plastic material or color",
    "frameupload.nodeinfo.materialTooltip.extra": "Uses extra material.",
    "frameupload.nodeinfo.materialTooltip.helper": "Special empty node used to connect/group.",
    "frameupload.nodeinfo.materialTooltip.unexpected": "Unexpected Node! Please check for typos!",

    "frameupload.otherfiles.description": "Here files can be stored that will not be shown in the app. Like blueprints or pictures of real glasses that were used when the 3D models were created.",
    "frameupload.otherfiles.noModelIdSavedYet": "To upload other files, an identifier has to be chosen and saved.",
    "frameupload.otherfiles.deletion.confirm": "Are you sure you want to delete \"{{name}}\"",
    "frameupload.otherfiles.tooltip.download": "Download File",
    "frameupload.otherfiles.tooltip.delete": "Delete File",
    "frameupload.otherfiles.selectfile": "Select new File",
    "frameupload.otherfiles.tooltip.upload": "Upload File",
    "frameupload.otherfiles.tooltip.cancel": "Deselect File",
    "frameupload.otherfiles.tableheader.name": "Name",
    "frameupload.otherfiles.tableheader.size": "Size",
    "frameupload.otherfiles.tableheader.lastModified": "Last modified",
    "frameupload.otherfiles.tableheader.dropFileHint": "Drop file to select for upload.",

    // materials
    "materials.createNew.button": "Create Material",

    "materialtable.header.name": "Name",

    "materialdetail.identifier": "Identifier",
    "materialdetail.title.new": "New Material",
    "materialdetail.title.edit": "Edit Material",
    "materialdetail.newlanguage.prompt": "Please enter the language code of the new language:",
    "materialdetail.localizedName.title": "Localized name:",
    "materialdetail.localizedName.label": "name",
    "materialDetail.save.button": "Save Material",
    "material.category": "Material type",
    "material.state": "State",
    "material.state.published": "Live",
    "material.state.testing": "Testing",
    "material.state.unpublished": "Unpublished",
    "material.type.glas": "Glass",
    "material.type.plastic": "Plastic",
    "material.type.metal": "Metal",
    "material.type.extra": "Extra",
    "material.shadertype": "Shader type",
    "material.shadertype.pbr": "PBR",
    "material.shadertype.blinn": "Blinn",
    "material.color.label": "Color",
    "material.physicalProperties.label": "Material properties",
    "material.textureProperties.label": "Textures",
    "material.textureImage.title": "Texture:",
    "material.textureImage.hint": "For materials with flowing colors",
    "material.normalTextureImage.title": "Normal Texture:",
    "material.normalTextureImage.hint": "Blue/Red image for bump mapping",

    "material.metallicity.tooltip": "How much the material is like a metal. Non-metallic materials such as wood or stone use 0.0, metallic use 1.0, with nothing (usually) in between. Default is 0.0. A value between 0.0 and 1.0 could be used for a rusty metal look.",
    "material.roughness.tooltip": "How rough the material appears. 0.0 means a smooth mirror reflection, 1.0 means fully diffuse. Default is 1.0. If roughnessMap is also provided, both values are multiplied.",
    "material.reflectivity.tooltip": "Degree of reflectivity, from 0.0 to 1.0. Default is 0.5, which corresponds to an index-of-refraction of 1.5. This models the reflectivity of non-metallic materials. It has no effect when metalness is 1.0",
    "material.anisotropy.tooltip": "Only used in blender so far.",
    "material.opacity.tooltip": "Float in the range of 0.0 - 1.0 indicating how transparent the material is. A value of 0.0 indicates fully transparent, 1.0 is fully opaque.",
    "material.clearcoat.tooltip": "Represents the intensity of the clear coat layer, from 0.0 to 1.0. Use clear coat related properties to enable multilayer materials that have a thin translucent layer over the base layer. Default is 0.0.",
    "material.clearcoatRoughness.tooltip": "Roughness of the clear coat layer, from 0.0 to 1.0. Default is 0.0.",
    "material.clearcoatNormalFactor.tooltip": "How much .clearcoatNormalMap affects the clear coat layer, from 0.0 to 5.0. Default is 1.0.",
    "material.transmission.tooltip": "Degree of transmission (or optical transparency), from 0.0 to 1.0. Default is 0.0. Thin, transparent or semitransparent, plastic or glass materials remain largely reflective even if they are fully transmissive. The transmission property can be used to model these materials.",
    "material.ior.tooltip": "Index-of-refraction for non-metallic materials, from 1.0 to 2.333. Default is 1.5.",
    "material.thickness.tooltip": "The thickness of the volume beneath the surface. The value is given in the coordinate space of the mesh. If the value is 0 the material is thin-walled. Otherwise the material is a volume boundary. Default is 0.",
    "material.normalScale.tooltip": "How much the normal map affects the material. Typical ranges are 0-1. Default is set to 1.0.",
    "material.attenuationColor.tooltip": "The color that white light turns into due to absorption when reaching the attenuation distance. Default is white (0xffffff).",
    "material.attenuationDistance.tooltip": "Density of the medium given as the average distance that light travels in the medium before interacting with a particle. The value is given in world space units, and must be greater than zero. Default is Infinity.",

    "footer.imprint": "Imprint",
    "footer.dataprivacy": "Data-Privacy",
    "footer.contact": "Contact",
    "footer.stagingSwitch": "Staging Env:",

    "privacy.title": "Privacy Declaration",

    "disclaimer.text": "*Your username is equal to your lower case email address.",

    "branduser.user-email": "User-Email",
    "branduser.role": "Role",
    "branduser.company": "Company",
    "branduser.name": "Name",
    "branduser.address": "Address",
    "branduser.max-devices": "Max Devices",
    "branduser.customerId": "Customer Id",
    "branduser.lastAuthenticated": "Last Seen",
    "branduser.firstCreated": "Created at",
    "branduser.dateunkwown": "Prior to 2023",
    "branduser.telNr": "Tel. No.",
    "branduser.filter.name": "Filter Name",
    "branduser.filter.zipcode": "Zipcode",
    "branduser.resend": "Resend Inv.",
    "branduser.resend.successful": "Invited",
    "branduser.resend.successfulAlert": "Successfully resend invitation email to {{USER}}.",
    "branduser.resend.hasAlreadyChangedPassword": "The user {{USER}} has already changed the temporary password but hasn't logged in on an iPhone or iPad yet.",
    "branduser.resend.failedAlert": "Error while resending invitation to {{USER}}: {{ERROR}}",
    "brandUsers.copyUserModal.title.copyfrom": "Copying users from",
    "brandUsers.copyUserModal.title.to": "to {{BRAND}}",
    "branduser.devices.none": "None",

    "option.none": "N.A.",
    "option.metal": "Metal {{NUMBER}}",
    "option.plastic": "Plastic {{NUMBER}}",
    "option.material": "Material {{NUMBER}}",
    "option.lenses.clear": "Fensterglas",
    "option.lenses.antireflective": "Antireflektiv",
    "option.lenses.milky": "Milchglas",
    "option.lenses.shaded": "Getönt",
    "option.lenses.mirrored": "Verspiegelt",

    "copydialog.title": "Copy of config",
    "copydialog.stage.test": "Testing",
    "copydialog.stage.prod": "Live",
    "copydialog.description": "Please choose the brand and stage from which to copy the app config.",
    "copydialog.button.title": "Copy&Edit",

    "duplicationdialog.title": "Duplication of {{NAME}}",
    "duplicationdialog.brand": "Brand",
    "duplicationdialog.category": "Collection",
    "duplicationdialog.description": "Please choose the brand and collection into which to copy the frame.",

    "errorTitle.dxf": "Unexpected error handling the dxf:",

    "DeepLinkCreator.title": "Direct Link to “{{model}}” from collection {{category}}:",

    "builds.title": "Settings",
    "builds.brandsettings.orderNotifications.Title": "How would you like to receive orders?",
    "builds.brandsettings.orderNotifications.ViaEmail": "By Email",
    "builds.brandsettings.orderNotifications.ViaHTTP": "As API-Call",
    "builds.brandsettings.orderNotifications.orderCallbackURL": "Callback URL",
    "builds.brandsettings.orderNotifications.invalidURL": "Incomplete URL address",
    "builds.brandsettings.orderNotifications.orderCallbackAPIKey": "API Key",
    "builds.brandsettings.orderNotifications.orderEmailDescription": "This Email will show as Sender of order-confirmations received by customers. When adding a new mail address, please remember to verify it with AWS email service before it can be used as a sender.",

    "builds.brandsettings.button": "Brand Settings",
    "builds.brandsettings.text": "Settings related to a brand, mostly used by the backend.",
    "builds.targetsettings.button": "Target Settings",
    "builds.targetsettings.text": "Settings used when building new app executables.",
    "builds.duplicateBrand.button": "Duplicate Brand",
    "builds.duplicateBrand.text": "Creates a new brand based on {{current}}'s settings",

    "settings.title": "Editing the {{name}} of <0>{{brand}}</0>:",

    "materialpreview.rotate": "Rotate",
    "materialpreview.background": "Backgr.",

    // GENERAL

    "prompt.unsavedChanges": "You have unsaved changes. Are you sure you want to leave this page?",

    "greeting": "Hello",

    "duplicate": "Duplicate",
    "edit": "Edit",
    "delete": "Delete",
    "cancel": "Cancel",
    "submit": "Submit"
};