export const de = {

    "home.title": "Willkommen im LooC-CMS, Sie sehen Inhalte der Marke: <i>{{brand}}</i>!",
    "home.platform.manager.description": "Dies ist eine Platform-Marke, Sie können hier vor allem Marken und Nutzer verwalten, aber keine Brillen hochladen.",
    "home.defaultbrand.manager.description": "Hier können Sie Bestellungen einsehen, Brillenmodelle hochladen und vieles mehr.",
    "home.defaultbrand.user.description": "Sie sind als Nutzer registriert unter {{username}}.",
    "home.brandMembership.title": "Sie sind in folgenden Marken registriert:",
    "home.brandMembership.switch": "Auswählen",

    "navigation.home": "Startseite",
    "navigation.user": "Nutzer",
    "navigation.users": "Nutzer",
    "navigation.stores": "Geschäfte (ohne App)",
    "navigation.devices": "Geräte",
    "navigation.orders": "Bestellungen",
    "navigation.frames": "3D Modelle",
    "navigation.appconfig": "Appkonfig.",
    "navigation.renderings": "Renderings",
    "navigation.builds": "Builds",
    "navigation.materials": "Materialien",
    "navigation.logout": "Ausloggen",
    "navigation.cost": "Kostenübersicht",

    "appconfig.title": "Anpassen der Test-Appkonfiguration von <i>{{brand}}</i>:",
    "appconfig.stage.prod": "Live",
    "appconfig.stage.test": "Testinhalte",
    "appconfig.empty": "Nicht besetzt.",
    "appconfig.createNew": "Neu erstellen",
    "appconfig.edit": "Anpassen",
    "appconfig.copy": "Kopieren",
    "appconfig.publish.confirm": "Sind Sie sicher, die gewählte Testkonfiguration für alle Appstorenutzer zu aktivieren? Am besten sollte die Testkonfiguration in der App bereits ausgiebig getestet worden sein.",
    "appconfig.texts.collectionHeader": "Kollektionenmenü Infotext:",
    "appconfig.texts.shoppingDisclaimer": "Warenkorb Titel Text",
    "appconfig.texts.shoppingDisclaimerSpecialSize": "Warenkorb Titel Text Spezialanfertigung",
    "appconfig.texts.customPlasticMaterialName": "Besondere Plastikkategoriebezeichnung",
    "appconfig.updateInfo.title": "Versionsdetails:",
    "appconfig.updateInfo.currentVersion": "Version",
    "appconfig.updateInfo.currentBuildNr.apple": "🍏 Build Nummer",
    "appconfig.updateInfo.currentBuildNr.android": "🤖 Build Nummer",
    "appconfig.updateInfo.downloadLink": "Downloadlink",
    "appconfig.updateInfo.contactEmail": "Kontaktemail",
    "appconfig.updateInfo.facedataEmail": "Email für Gesichtsdaten-Ergebnisse",
    "appconfig.headerImage.title": "Kollektionenmenü Header:",
    "appconfig.promoImage.title": "Marketing Photo:",
    "appconfig.promoPhoto.tooltip": "Bild wird u.a. im Platform Menü neben Ihrer Marke angezeigt. Idealerweise {{SIZE}}px.",
    "appconfig.tags.foregroundcolor": "Textfarbe",
    "appconfig.tags.backgroundcolor": "Hintergrundfarbe",
    "appconfig.other": "Sonstiges",

    "statistics.hits": "Anzahl",
    "statistics.diskusage": "CMS-Speichernutzung: {{mb}} MB (Stand {{date}})",
    "statistics.dayAxis.title": "Tag",
    "statistics.legend.mapHits": "Kartenabfrage",
    "statistics.legend.AppCheckins": "App-Inhaltabfragen",
    "statistics.legend.AppCheckins.monthtotal": "App-Starts: {{SUM}}",
    "statistics.legend.Orders": "Bestellungen",
    "statistics.legend.WebVTO": "WebGL-Starts",
    "statistics.legend.WebVTO.monthtotal": "Summe {{DAYS}} Tage: {{SUM}}",
    "statistics.dailyDetails": "Am {{date}} wurden {{appData}} App-Inhalte aktualisiert, {{mapHits}} mal wurden Karten angefragt und das System hat Bestellungen über {{orders}} Brillen erhalten.",
    "statistics.dailyDetails.appVersions.title": "Installierte App Versionen:",
    "statistics.dailyDetails.osVersions.title": "iOS Versionen:",
    "statistics.nodata": "Keine Daten über die App-Nutzung von {{brand}} verfügbar.",

    "categories.unauthorized": "Sie haben keine Berechtigung um auf die Kategorien von <i>{{brand}}</i> zuzugreifen!",
    "categories.title": "Hier sehen Sie die Brillenkategorien/Kollektionen von <i>{{brand}}</i>:",
    "categories.createNew": "Neue Kategorie/Kollektion erstellen",
    "categories.refreshNow": "Jetzt alle Server-Caches aktualisieren.",
    "categories.refreshNow.text": "Startet eine Invalidierung der Server-Caches. Diese Operation kostet Geld, bitte nur wenn nötig benutzen und nicht öfter als 1 x täglich.",
    "categories.refreshNow.confirmationText": "Sind Sie sich sicher? Alle iPhones und iPads mit der {{brand}} App werden beim nächsten Start dazu aufgefordert ihre Modelle zu aktualisieren. Bitte klicken Sie hier nicht zu oft, um Nutzer nicht unnötig zu nerven.",

    "category.image": "Bild",
    "category.title": "Title",
    "category.details": "Details",
    "category.status": "Status",
    "category.promoted": "Im Schaufenster",
    "category.promoted.yes": "Ja",
    "category.promoted.no": "Nein",
    "category.button.promote": "Anzeigen",
    "category.button.unpromote": "Rausnehmen",
    "category.actions": "Aktionen",
    "category.showModels": "Modelle",
    "category.button.startTesting": "Testen",
    "category.button.publish": "Aktivieren",
    "category.button.unpublish": "Deaktivieren",
    "category.state.unpublished": "Inaktiv",
    "category.state.testing": "Im Testmodus",
    "category.state.published": "Aktiv",

    "categorytable.tooltip.editMaterials": "Kollektionsdetails einstellen",
    "categorytable.tooltip.edit": "Erlaubte Materialien für gesamte Kollektions anpassen.",
    "categorytable.materialsetting.title": "Materialeinstellungen für alle Brillen in <i>{{CATEGORY}}</i>",
    "categorytable.materialsetting.description": "Achtung, für alle Brillen in dieser Kollektion werden alle Materialeigenschaften überschrieben.",
    "categorytable.materialsetting.overwrite": "Überschreiben.",

    "categorycreate.notauthorized": "Sie sind nicht authorisiert Kategorien für <i>{{brand}}</i> zu ändern!",
    "cartegoryedit.title": "Anpassen der Daten von <i>{{category}}</i>",
    "categorycreate.title": "Neue Kategorie/Kollektion für <i>{{brand}}</i>:",
    "categorycreate.categoryname.label": "Systemname",
    "categorycreate.categorytitel.label": "Titel",
    "categorycreate.categorydetail.label": "Detail",
    "categorycreate.newlanguage.buttontext": "Zusätzliche Sprache",
    "categorycreate.newlanguage.prompt": "Bitte geben Sie den Sprachkürzel der neuen Sprache ein:",
    "categorycreate.form.required": "Pflichtfeld",
    "categorycreate.localizations.language": "Sprache",
    "categorycreate.localizedTitle.title": "Lokalisierte Titel:",
    "categorycreate.localizedDetails.title": "Lokalisierte Details:",
    "categorycreate.warning.nameMissingOrShort": "Der Name muss min 3 Zeichen haben.",
    "categorycreate.warning.nameInvalidCharacters": "Name bitte nur Buchstaben und Zahlen.",
    "categorycreate.settings": "Sonstige Einstellungen",

    "categorymodeltable.title": "Liste der Brillenmodelle in <i>{{category}}</i>:",

    "categoryContent.addNewModels.Button": "Neues Brillenmodell hinzufügen",

    "categoryPicker.label": "Kollektion",
    "categoryPicker.all": "alle",

    "cookiePopup.text": "Können wir Cookies benutzen damit sich diese Seite Ihre Einstellungen merken kann?",
    "cookiePopup.yes": "Cookies akzeptieren",
    "cookiePopup.no": "Keine Cookies",

    "modelPicker.label": "Modell",
    "modelPicker.all": "alle",

    "time.ago": "vor {{time}}",

    "renderings.title": "Renderings von ",
    "rendering.categoryAndModel": "Modell",
    "rendering.dateCreated": "Angefragt",
    "rendering.duration": "Dauer",
    "rendering.cost": "Kosten",
    "rendering.status": "Status",
    "rendering.result": "Ergebnis",
    "rendering.tooltip.open": "Im Browser öffnen",
    "rendering.tooltip.copyToClipBoard": "Link in Zwischenablage kopieren",
    "rendering.tooltip.logs": "Logs des Renderprozesses",
    "rendering.tooltip.reloadParameters": "Renderparameter nochmal laden",
    "rendering.tooltip.delete": "Löschen",
    "rendering.tooltip.error": "Es ist ein Fehler beim Rendern aufgetreten.",
    "rendering.tooltip.waiting": "Der Auftrag wartet auf einen freien Computer.",

    "renderings.nodeselector.none": "keine",
    "renderings.nodeselector.title": "Gleiches Material wie",
    "renderings.requestNew": "Neues Rendering starten",
    "renderings.batch": "Bündelauftrag",
    "renderings.clearcache": "Cache leeren",
    "renderings.linkTo": "Zu den Renderings",
    "renderings.delete.button": "{{count}} löschen",
    "renderings.monthlyCost": "Kosten {{month}}: {{cost}}",
    "rendering.modelviewer.norenderingerror": "Please go back and open the model again",
    "renderingtype.picker": "Typ",
    "receipts.rendering": "Rendern von {{type}} ({{res}})",
    "receipts.renderingcosts.title": "Renderings",
    "receipts.monthlyCosts": "Monatliche Kundenabrechnung",
    "receipts.monthlyCosts.costingStores": "Anzahl kostenpflichtige Filialen: {{stores}}",
    "receipts.monthlyCosts.details.nonVTO": "Non-VTO Geschäfte: {{nonVTO}}",
    "receipts.monthlyCosts.details.freeVTO": "Kostenlose Länder: {{freeVTO}}",
    "receipts.monthlyCosts.details.vtoPaying": "Kostenpflichtige Geschäfte: {{vtoPaying}}",
    "receipts.monthlyCosts.details.orders": "Bestellungen: {{orders}}",
    "receipts.monthlyCosts.details.appCheckins": "App Starts: {{total}}, davon kostenlos: {{free}}, kostenpflichtig: {{charged}}",
    "receipts.monthlyCosts.details.webVTO": "WebVTO: {{total}}, davon kostenlos: {{free}}, kostenpflichtig: {{charged}}",

    "RenderingRequest.Title": "Neues Rendering für {{brand}}",
    "RenderingRequest.Scene": "Blender Szene:",
    "RenderingRequest.ForceFree": "Kostenlos setzen",
    "RenderingRequest.Environment": "Lichtumgebung:",
    "RenderingRequest.EstimatedCosts": "Geschätzte Kosten: {{cost}}",
    "RenderingRequest.FreePreview": "Kostenlose Vorschau",
    "RenderingRequest.Urgent": "Dringend",
    "RenderingRequest.Type.image": "Bild",
    "RenderingRequest.Type.video": "Video",
    "RenderingRequest.Type.usdz": "AR Quick Look",
    "RenderingRequest.Type.gltf": "ModelViewer",
    "RenderingRequest.Denoise": "Entrauschen",
    "RenderingRequest.Denoise.ToolTip": "Entrauschen kann die Qualität von glatten Oberflächen erhöhen.",
    "RenderingRequest.DOF": "Schärfentiefe",
    "RenderingRequest.DOF.ToolTip": "Schärfentiefe (Depth of Field) fokussiert die Front der Brille und lässt die Bügel im Hintergrund leicht anweichen.",
    "RenderingRequest.Samples": "Qualität (Samples)",
    "RenderingRequest.Samples.ToolTip": "Höhere Sample-Anzahl erhöht die Qualität, verbraucht aber mehr Rechenleistung.",
    "RenderingRequest.Angle": "Winkel",
    "RenderingRequest.Brightness": "Helligkeit",
    "RenderingRequest.Type": "Typ",
    "RenderingRequest.Resolution": "Auflösung",
    "RenderingRequest.Request.button": "Rendering starten",
    "RenderingRequest.Request.confirm": "Sind Sie sicher ein {{item}} zum Preis von {{price}} in Auftrag geben wollen? Die anfallenden Kosten können nicht mehr rückgängig gemacht werden.",
    "RenderingRequest.Request.Status.Uploading": "Auswahl wird an Server gesendet",
    "RenderingRequest.Request.Status.DownloadingGlasses": "Brillenmodell wird heruntergeladen",
    "RenderingRequest.Request.MaterialTable.Header.Node": "Node",
    "RenderingRequest.Request.MaterialTable.Header.Material": "Material",
    "RenderingRequest.material.option.none": "Nichts gewählt",
    "renderings.deletion.confirm": "Sind Sie sicher, dass Sie \"{{model}}\" löschen wollen?",

    "BatchRenderingRequest.Title": "gebündelter USDZ/GLTF Renderauftrag",
    "BatchRenderingRequest.Models.Title": "Modelle:",
    "BatchRenderingRequest.Materials.Title": "Farben:",
    "BatchRenderingRequest.3DModelsNeedRefetching.Warning": "Eins der ausgewählten Modelle wurde noch nicht heruntergeladen.",
    "BatchRenderingRequest.SpecialNodes.Title": "Spezial/Extra Objektfarben:",
    "BatchRenderingRequest.SpecialNode.Subtitle.Title": "Knoten {{name}} kommt {{count}} mal in ausgewählten Modellen vor:",
    "BatchRenderingRequest.Materials.Totals": "{{totalCount}} GLTF&USDZ für {{models}} Modellkombinationen in {{materialcombinations}} Materialkombinationen kostet {{price}}",
    "BatchRenderingRequest.Prompt": "Sind Sie sicher {{totalCount}} Dateien zum Preis von {{price}} in Auftrag geben zu wollen? Die anfallenden Kosten können nicht mehr rückgängig gemacht werden.",
    "BatchRenderingRequest.Request.Button": "3D Dateien Erstellung beauftragen",
    "BatchRenderingRequest.FetchAndAnalyzeModels.Button": "3D Modelle auf extra Knoten analysieren",
    "BatchRenderingRequest.UploadStatus": "{{percent}} Beauftrage {{model}} in {{metal}} und {{plastic}}",

    "modeltable.sizes": "Standardmaße",
    "modeltable.status": "Status",
    "model.image": "Vorschaubild",
    "model.title": "Titel",
    "model.actions": "Aktionen",
    "model.status.unfinished": "Unvollständig",
    "model.status.transcoding": "Wird verarbeitet",

    "role.assigned": "Sie sind <i>{{role}}</i> für die Marke <i>{{brand}}</i>",

    "userlist.unauthorized": "Sie haben keine Berechtigung um auf Nutzer von <i>{{brand}}</i> zuzugreifen!",
    "userlist.title": "Nutzer von <i>{{brand}}</i>:",
    "userlist.invite": "Neuer Nutzer",
    "userlist.add": "Nutzer kopieren",
    "userlist.copy.users": "{{count}} Nutzer kopieren",
    "userlist.rebuild_stores": "Geschäftskartenpositionen neu berechnen",
    "userlist.rebuild_stores.confirm": "Sind Sie sicher, die aufwendige Berechnung der GPS Koordinaten aller bestehenden Geschäfte jetzt durchführen zu wollen? Bitte tun Sie dies nur einmal täglich.",
    "userlist.option.showLastused": "Lade wann zuletzt App verwendet",

    "usercreate.notauthorized": "Sie sind nicht authorisiert Nutzer für <i>{{brand}}</i> zu ändern!",

    "useredit.title": "Anpassen der Daten von <i>{{user}}</i>",
    "usercreate.title": "Erstelle einen neuen Nutzer für <i>{{brand}}</i>:",
    "usercreate.form.email": "Email",
    "usercreate.form.company": "Unternehmen des Kunden",
    "usercreate.form.first-name": "Vorname",
    "usercreate.form.last-name": "Nachname",
    "usercreate.form.address": "Adresse",
    "usercreate.form.zip-code": "PLZ",
    "usercreate.form.city": "Stadt",
    "usercreate.form.country": "Land",
    "usercreate.form.region": "Bundesland",
    "usercreate.form.access-level": "Berechtigung",
    "usercreate.form.max-devices": "Max Geräte",
    "usercreate.form.telNr": "Telefon Nr.",
    "usercreate.form.telnrlength": "Telefon Nr. zu kurz",
    "usercreate.form.web": "Webseite",
    "usercreate.form.mailCC": "Email CC an",
    "usercreate.form.customerId": "Kundennummer",
    "usercreate.form.invalidEmail": "Ungültige Email Adresse",
    "usercreate.form.required": "Pflichtfeld",
    "usercreate.form.role-store": "Optiker",
    "usercreate.form.role-manager": "Manager",
    "usercreate.stores": "Geschäfte (mit App):",

    "usercreate.brandmembership": "Markenmitgliedschaft:",
    "usercreate.brandmembership.nomember": "Nicht Mitglied",
    "useredit.legacybrand": "Altkunde: {{BRAND}}",

    "devicelist.unregistered": "Sie haben noch keine Geräte für diese Marke registriert.",
    "devicelist.title": "Das sind Ihre registrierten Geräte:",
    "devicelist.model": "Model",
    "devicelist.device-id": "Geräte-Id",
    "devicelist.last-used": "Zuletzt benutzt",
    "devicelist.name": "Name",

    "orderlist.no-order-from-brand": "Sie haben noch nichts von {{brand}} bestellt.",
    "orderlist.no-order-for-brand": "Es gibt noch keine Bestellungen für {{brand}}.",
    "orderlist.your-orders-from-brand": "Das sind Ihre bisherigen Bestellungen von {{brand}}:",
    "orderlist.orders-for-brand": "Das sind alle Bestellungen für {{brand}}:",
    "orderlist.date": "Bestelldatum",
    "orderlist.user": "App-Nutzer",
    "orderlist.contact": "Kontakt",
    "orderlist.details": "Modelle",
    "orderlist.more": "Mehr laden",

    "storelist.manager.title": "Geschäfte, die keine App-Nutzer sind, aber trotzdem '{{brand}}' Produkte führen.",
    "storelist.manager.reminder": "Die Adressen der Geschäfte, die VTO App-Nutzer sind, werden in der Nutzer-Liste verwaltet. ⚠️",
    "storelist.optician.title": "Hier können Sie Ihre eigenen Geschäftsadressen von ({{company}}) einstellen.",
    "storelist.new": "Neu",

    "orderDetails.title": "Bestellung vom {{date}} von {{store}}:",
    "orderDetails.table.header.model": "Modell",
    "orderDetails.table.header.special": "Sonderanfertigung",
    "orderDetails.table.header.details": "Details",
    "orderDetails.table.header.engraving": "Gravur",
    "orderDetails.table.header.comment": "Kommentar",

    "OrderOption.Frame": "Rahmen",
    "OrderOption.FrontMetalColor": "Frontfarbe",
    "OrderOption.MetalColor": "Metallfarbe",
    "OrderOption.TempleColor": "Bügelfarbe",
    "OrderOption.LensType": "Gläser",
    "OrderOption.OverallSize": "Größe",
    "OrderOption.BridgeWidth": "Brückenbreite",
    "OrderOption.TempleLength": "Bügellänge",
    "OrderOption.GlasWidth": "Glasbreite",
    "OrderOption.GlasHeight": "Glashöhe",
    "OrderOption.PadType": "Pads",

    "detail_item_default_size": "Standard ({{SIZE}})",
    "detail_item_special_size": "Sondergröße ({{SIZE}})",

    "frameupload.general.filesize": "Dateigröße: ",
    "frameupload.tab.general": "Allgemein",
    "frameupload.tab.sizes": "Größen",
    "frameupload.tab.colors": "Farbe&Material",
    "frameupload.tab.files": "Sonstiges",
    "frameupload.title": "Neues Modell in <i>{{CATEGORY}}<i>",
    "frameupload.editTitle": "Anpassen von <i>{{MODEL}}<i>",
    "frameupload.fileUpload": "3D Model hier ablegen oder klicken um auszuwählen.",
    "frameupload.modelIsResizable": "Modell erlaubt Sondergrößeneinstellungen:",
    "frameupload.unknownFileType": "Unbekanntes Dateiformat \"{{FILEEXTENSION}}\".\nBitte versuchen Sie z.b. Collada *.dae .",
    "frameupload.errorImportingFile": "Fehler beim importieren der Datei:\n{{ERROR}}",
    "frameupload.referenceGlassesFrame": "Referenzbrille anzeigen.",
    "frameupload.checkUVs": "UVs prüfen.",
    "frameupload.noWarnings": "Keine Fehler gefunden!",
    "frameupload.templeParameter": "Bügelparameter: {{PARAMETER}}",
    "frameupload.notUnlocked": "Das Hochladen von 3D Modellen ist für {{BRAND}} noch nicht freigeschalten.",
    "frameupload.thumbImageUpload": "Vorschaubild (mindestens {{SIZE}}, gerne höhere Auflösung)",
    "frameupload.identifier": "Identifier",
    "frameupload.tags": "Tags:",
    "frameupload.addTag.Button": "+Tag",
    "frameupload.collection": "Kollektion",
    "frameupload.thumbnailTitle": "Vorschaubild",
    "frameupload.allowsLensCustomization": "Gläser einstellbar",
    "frameupload.allowsPlasticCustomization": "Plastikfarbe einstellbar",
    "frameupload.allowsMetalCustomization": "Metall einstellbar",
    "frameupload.allowsExtraMaterialCustomization": "Extramat. einstellbar",
    "frameupload.allowsSeparateTempleColor": "Bügelfarbe einstellbar",
    "frameupload.restrictedLenses": "Gläser einschränken",
    "frameupload.restrictedLenses.table.title": "Auswählbare Gläser:",
    "frameupload.restrictedPlastics": "Plastikfarben einschränken",
    "frameupload.fixedNodes.table.title": "Fixierte Objektmaterialien:",
    "frameupload.restrictedPlastics.table.title": "Auswählbare Plastikm.:",
    "frameupload.restrictedMetals": "Metalle einschränken",
    "frameupload.restrictedMetals.table.title": "Auswählbare Metalle:",
    "frameupload.restrictedExtraMats": "Extramat. einschränken",
    "frameupload.restrictedExtraMats.table.title": "Auswählbare Extramat.:",
    "frameupload.shadedByDefault": "Getönte Scheiben",
    "frameupload.templesUsePadMaterial": "Durchsichtiges Bügelplastik",
    "frameupload.header.defaultSizes": "Standardwerte (in mm):",
    "frameupload.size.defaultBridgeSize": "Brückenbreite",
    "frameupload.size.defaultGlasWidth": "Glasbreite",
    "frameupload.size.defaultGlasHeight": "Glashöhe",
    "frameupload.size.defaultTempleLength": "Bügellänge",
    "frameupload.header.testSizeChange": "Größenänderungen testen:",
    "frameupload.upload.button": "Erstellen&Hochladen",
    "frameupload.uploadAndChange.button": "Änderungen speichern",
    "frameupload.option.textureTransformScale": "UV Skalierung",
    "frameupload.option.minimumAppVersion": "Min. Buildnr.",
    "frameupload.fixedsizes.allowFixedSizeSteps": "Versch. Größenoptionen",
    "frameupload.fixedsizes.name.header": "Bezeichnung",
    "frameupload.fixedsizes.sizeFactor.header": "Skalierung",
    "frameupload.fixedsizes.name.label": "Name",
    "frameupload.fixedsizes.sizeFactor.label": "Faktor",
    "frameupload.fixedsizes.addSize.button": "Zusätzl. Größe",
    "frameupload.fixedsizes.addSize.prompt": "Bezeichnung für neue Größe:",
    "frameupload.fixedBridgeSizes.title": "Limitierte Brückengrößen",
    "frameupload.fixedBridgeSizes.header": "Größe:",
    "frameupload.fixedBridgeSizes.addSize.button": "+ Größe",
    "frameupload.dragInFileReminder": "Neue Dateien bitte an beliebiger Stelle ablegen",
    "frameupload.modelheadNode": "Kopf 3D Datei",
    "frameupload.thumbnailtobig.alert": "Das gewählte Bild ist {{SIZE}} groß, bitte wählen Sie ein Bild mit einer Größe von maximal {{MAXIMUMSIZE}}.",

    "frameupload.status.dxfSevereErrorCount": "Die DXF Datei hat {{ERRORS}} Fehler die berichtigt werden müssen, bevor Sie die Änderungen speichern können.",
    "frameupload.status.frameAnalysis": "3D Model wird analysiert",
    "frameupload.status.dxfAnalysis": "DXF Datei wird analysiert",
    "frameupload.status.createDBEntry": "Erstelle Datenbankeintrag",
    "frameupload.status.UploadingThumbnail": "Bild wird hochgeladen",
    "frameupload.status.UploadingModel": "3D-Modell wird hochgeladen",
    "frameupload.status.UploadingDXF": "Schnittmuster wird hochgeladen",
    "frameupload.status.DownloadingModel": "3D-Modell wird heruntergeladen",
    "frameupload.status.DownloadingDXF": "Schnittmuster wird heruntergeladen",

    "frameupload.DXFViewer.mergeHinge": "Backe verschmelzen",
    "frameupload.DXFViewer.mergePad": "Pads verschmelzen",
    "frameupload.DXFViewer.Input": "In",
    "frameupload.DXFViewer.Output": "Out",

    "frameupload.coloroptions.lens": "Voreing. Glas",
    "frameupload.coloroptions.metal": "Voreing. Metall",
    "frameupload.coloroptions.plastic": "Voreing. Plastik",
    "frameupload.coloroptions.separateTempleColor": "Sep. Bügelplastik",
    "frameupload.coloroptions.extraMaterial": "Material {{LETTER}}",
    "frameupload.coloroptions.fixedNode.addNewRow": "Festes Material für Node",

    "frameupload.localizedname.title": "Lokal. Name",
    "frameupload.localizedname.label": "Name",
    "frameupload.warning.nodeMissing": "Objekt {{NODE}} konnte nicht gefunden werden, wird aber benötigt.",
    "frameupload.warning.faultyNodeName": "Objekt {{NODE_NAME}} wurde gefunden, hat aber einen ungültige Zeichen im Namen \"{{WRONG_NAME}}\" die zu Problemen führen können.",
    "frameupload.warning.siblingNode": "Unerwünschtes Objekt {{NODE}} neben Glasses gefunden.",
    "frameupload.warning.glassesNodeMissing": "\"Glasses\" Objekt fehlt, auf der höchsten Ebene sollte nur ein Objektknoten liegen der \"Glasses\" heißt und dem alle Brillenbestandteile gehören.",
    "frameupload.warning.lensNodesMissing": "Im 3D Modell fehlt das \"LENSES\" Objekt bzw. die \"LENS_RIGHT\" & \"LENS_LEFT\" Objekte wenn es sich um ein größenveränderliches Modell handeln soll.",
    "frameupload.warning.extraNode": "Unerwartetes Objekt {{NODE}} gefunden.",
    "frameupload.warning.extraUnnamedNode": "Unerwartetes unbenanntes Objekt gefunden.",
    "frameupload.warning.extraNodeInTwoCategories": "Buchstabe \"{{LETTER}}\" kommt sowohl in Materialkategorie {{MATTYPE1}} als auch {{MATTYPE2}} vor. Bitte in einer Kategorie einen anderen Buchstaben benutzen.",
    "frameupload.warning.duplicatedNode": "{{NODE}} doppelt enthalten.",
    "frameupload.warning.degenerateMatrix": "{{NODE}}'s 'rotation' oder 'scale' sind degeneriert, vielleicht sollte dieses Objekt gelöscht werden.",
    "frameupload.warning.scaledNode": "{{NODE}}'s ist skaliert, sollte aber idealerweise (1,1,1) als Skalierung haben.",
    "frameupload.warning.rotatedNode": "{{NODE}}'s ist verdreht, bitte alle rotation Winkel auf 0º stellen.",
    "frameupload.warning.nodesShouldCoincide": "{{NODEA}} sollte an derselben Stelle liegen wie {{NODEB}}'s Koordinatenursprung.",
    "frameupload.warning.multipleGeometries": "Unterobject {{CHILD}} von {{NODENAME}} hat eine Geometrie aus {{COUNT}} Teilen, was oft zu Problemen führt. Z.b. wenn versehentlich Metallteile zusammen mit Plasteteilen in FRONT_LEFT/RIGHT liegen.",
    "frameupload.warning.fileSize": "Das 3D Model ist größer als {{MAXSIZE}}MB, um Ladezeiten gering zu halten empfehlen wir darunter zu bleiben.",

    "frameupload.dxfwarning.WarningTitle": "Die bereitgestellte dxf Datei ist problematisch, denn:",
    "frameupload.dxfwarning.missingColor": "Ein Objekt vom typ {{TYPE}} hat keine Farbe, bitte prüfen Sie nach ob sie vielleicht 'Color by Layer' gesetzt haben.",
    "frameupload.dxfwarning.duplicate": "In der DXF Datei wurden {{DUPLICATES}} alle mit derselben Farbe gefunden. Jedes Objekt sollte seine eigene Farbe und Beschriftung haben.",
    "frameupload.dxfwarning.missingAnnotation": "Ihre DXF Datei beinhaltet keine Bemaßung für '{{NAME}}'.",
    "frameupload.dxfwarning.missingCurve": "Für das Teil '{{NAME}}' wurden keine Kurve mit der gleichen Farbe gefunden.",
    "frameupload.dxfwarning.noLineConnects1And2": "'{{PART1}}' und '{{PART2}}' sollten sich an der Kontaktstelle exakt überlagern, an der die Kurven verschmolzen werden sollen.",
    "frameupload.dxfwarning.unexpectedLineCountConnectingPadAndShape": "Pad und Shape sollten sich beide in genau einer Linie überlagern, es gibt aber {{COUNT}} Linien.",
    "frameupload.dxfwarning.partsMissing": "Zurzeit können nur Modelle die mindestens Teile shape, bridge und pad haben in eine Brille zusammengebaut werden.",
    "frameupload.dxfwarning.missingConnection": "Es konnte keine Verbindungslinie gefunden werden, deshalb wird die Brille nicht verschmolzen",
    "frameupload.dxf.uploadForQualityDXF": "Um ein größenverändertes DXF zu erzeugen, muss das Original-DXF zuerst in die Cloud hochgeladen werden.",
    "frameupload.dxf.fileConversionOngoing": "Die hochgeladene Original-DXF wird gerade umgewandelt, bitte laden Sie diese Seite in wenigen Minuten neu.",

    "frameupload.purchaseLinks.title": "Link zum Webshop",
    "frameupload.purchaseLinks.label": "Link",
    "frameupload.purchaseLinks.newRow": "Land hinzufügen",
    "frameupload.purchaseLinks.new.prompt": "Bitte geben Sie das Länderkennzeichen für den neuen Webshop-Link ein (z.b. 'de_DE')",

    "frameupload.tags.new.prompt": "Bitte geben Sie den neuen Tag ein:",
    "frameupload.tags.newOption": "✚ neuen Tag",
    "frameupload.info.lastEdited": "Zuletzt aktualisiert von {{USER}} am {{DATE}}.",

    "frameupload.nodeinfo.isSplit": "Modell ist zur Formanpassung zerschnitten:",
    "frameupload.nodeinfo.materialTooltip.metal": "Nutzt Metall-Material bzw Farbe.",
    "frameupload.nodeinfo.materialTooltip.glas": "Nutzt Glas-Material bzw Farbe.",
    "frameupload.nodeinfo.materialTooltip.plastic": "Nutzt Plastik-Material bzw Farbe.",
    "frameupload.nodeinfo.materialTooltip.extra": "Nutzt Extra-Material bzw Farbe.",
    "frameupload.nodeinfo.materialTooltip.helper": "Leeres Objekt zur Strukturierung",
    "frameupload.nodeinfo.materialTooltip.unexpected": "Unerwartetes Objekt! Bitte auf Rechtschreibfehler prüfen.",

    "frameupload.otherfiles.description": "Hier können zusätzliche Dateien gelagert werden, die in der App nicht angezeigt werden. Wie z.b. Konstruktionszeichnungen oder Photos der realen Brillen, die beim Modellieren der 3D dateien benutzt werden.",
    "frameupload.otherfiles.noModelIdSavedYet": "Um Dateien hochzuladen muss zuerst ein Identifier gewählt und gespeichert werden.",
    "frameupload.otherfiles.deletion.confirm": "Sind Sie sicher, dass Sie \"{{name}}\" löschen wollen?",
    "frameupload.otherfiles.tooltip.download": "Datei herunterladen",
    "frameupload.otherfiles.tooltip.delete": "Löschen dieser Datei",
    "frameupload.otherfiles.selectfile": "Neue Datei auswählen",
    "frameupload.otherfiles.tooltip.upload": "Datei hochladen",
    "frameupload.otherfiles.tooltip.cancel": "Dateiauswahl rückgängig machen",
    "frameupload.otherfiles.tableheader.name": "Name",
    "frameupload.otherfiles.tableheader.size": "Größe",
    "frameupload.otherfiles.tableheader.lastModified": "Datum",
    "frameupload.otherfiles.tableheader.dropFileHint": "Legen Sie die Datei hier ab um sie auszuwählen.",

    // materials
    "materials.createNew.button": "Neues Material",

    "materialtable.header.name": "Name",

    "materialdetail.identifier": "Identifier",
    "materialdetail.title.new": "Neues Material",
    "materialdetail.title.edit": "Material anpassen",
    "materialdetail.newlanguage.prompt": "Bitte geben Sie den Sprachkürzel der neuen Sprache ein:",
    "materialdetail.localizedName.title": "Lokalisierter Name:",
    "materialdetail.localizedName.label": "Name",
    "materialDetail.save.button": "Material speichern",
    "material.category": "Materialkategorie",
    "material.state": "Status",
    "material.state.published": "Live",
    "material.state.testing": "Im Test",
    "material.state.unpublished": "Inaktiv",
    "material.type.glas": "Glas",
    "material.type.plastic": "Plastik",
    "material.type.metal": "Metall",
    "material.type.extra": "Extra",
    "material.shadertype": "Shadertyp",
    "material.shadertype.pbr": "PBR",
    "material.shadertype.blinn": "Blinn",
    "material.color.label": "Farbe",
    "material.physicalProperties.label": "Materialeigenschaften",
    "material.textureProperties.label": "Texturwerte",
    "material.textureImage.title": "Textur:",
    "material.textureImage.hint": "Für Materialen mit Farbverläufen",
    "material.normalTextureImage.title": "Normalen-Textur",
    "material.normalTextureImage.hint": "Zur Erzeugung von Mikrooberflächenstrukturen, Blau/Rot",

    "material.metallicity.tooltip": "How much the material is like a metal. Non-metallic materials such as wood or stone use 0.0, metallic use 1.0, with nothing (usually) in between. Default is 0.0. A value between 0.0 and 1.0 could be used for a rusty metal look.",
    "material.roughness.tooltip": "How rough the material appears. 0.0 means a smooth mirror reflection, 1.0 means fully diffuse. Default is 1.0. If roughnessMap is also provided, both values are multiplied.",
    "material.reflectivity.tooltip": "Degree of reflectivity, from 0.0 to 1.0. Default is 0.5, which corresponds to an index-of-refraction of 1.5. This models the reflectivity of non-metallic materials. It has no effect when metalness is 1.0",
    "material.anisotropy.tooltip": "Only used in blender so far.",
    "material.opacity.tooltip": "Float in the range of 0.0 - 1.0 indicating how transparent the material is. A value of 0.0 indicates fully transparent, 1.0 is fully opaque.",
    "material.clearcoat.tooltip": "Represents the intensity of the clear coat layer, from 0.0 to 1.0. Use clear coat related properties to enable multilayer materials that have a thin translucent layer over the base layer. Default is 0.0.",
    "material.clearcoatRoughness.tooltip": "Roughness of the clear coat layer, from 0.0 to 1.0. Default is 0.0.",
    "material.clearcoatNormalFactor.tooltip": "How much .clearcoatNormalMap affects the clear coat layer, from 0.0 to 5.0. Default is 1.0.",
    "material.transmission.tooltip": "Degree of transmission (or optical transparency), from 0.0 to 1.0. Default is 0.0. Thin, transparent or semitransparent, plastic or glass materials remain largely reflective even if they are fully transmissive. The transmission property can be used to model these materials.",
    "material.ior.tooltip": "Index-of-refraction for non-metallic materials, from 1.0 to 2.333. Default is 1.5.",
    "material.thickness.tooltip": "The thickness of the volume beneath the surface. The value is given in the coordinate space of the mesh. If the value is 0 the material is thin-walled. Otherwise the material is a volume boundary. Default is 0.",
    "material.normalScale.tooltip": "How much the normal map affects the material. Typical ranges are 0-1. Default is set to 1.0.",
    "material.attenuationColor.tooltip": "The color that white light turns into due to absorption when reaching the attenuation distance. Default is white (0xffffff).",
    "material.attenuationDistance.tooltip": "Density of the medium given as the average distance that light travels in the medium before interacting with a particle. The value is given in world space units, and must be greater than zero. Default is Infinity.",

    "footer.imprint": "Impressum",

    "footer.dataprivacy": "Datenschutz",
    "footer.contact": "Kontakt",
    "footer.stagingSwitch": "Testserver:",

    "disclaimer.text": "*Ihre Email-Adresse (kleingeschrieben) ist gleichzeitig Ihr Benutzername.",

    "privacy.title": "Datenschutzerklärung",

    // branduser
    "branduser.user-email": "Nutzer-Email",
    "branduser.role": "Rolle",
    "branduser.company": "Kunde",
    "branduser.name": "Name",
    "branduser.address": "Adresse",
    "branduser.max-devices": "Max Geräte",
    "branduser.customerId": "Kundennummer",
    "branduser.lastAuthenticated": "Zuletzt angemeldet",
    "branduser.firstCreated": "Erstellt am",
    "branduser.dateunkwown": "Vor 2023",
    "branduser.telNr": "Telefon Nr.",
    "branduser.filter.name": "Filter Name",
    "branduser.filter.zipcode": "PLZ",
    "branduser.resend": "Neu einl.",
    "branduser.resend.successfulAlert": "Erneute Einladungsmail an {{USER}} wurde erfolgreich verschickt",
    "branduser.resend.hasAlreadyChangedPassword": "Der Nutzer {{USER}} hat die Einladung bereits akzeptiert aber sich noch nicht auf einem iPhone oder iPad eingeloggt.",
    "branduser.resend.failedAlert": "Fehler beim versenden der Einladungsmail an {{USER}}: {{ERROR}}",
    "branduser.resend.successful": "Eingeladen",
    "brandUsers.copyUserModal.title.copyfrom": "Kopieren von Nutzern von",
    "brandUsers.copyUserModal.title.to": "nach {{BRAND}}",
    "branduser.devices.none": "Keine",

    "option.none": " - ",
    "option.metal": "Metall {{NUMBER}}",
    "option.plastic": "Plastik {{NUMBER}}",
    "option.material": "Material {{NUMBER}}",
    "option.lenses.clear": "Fensterglas",
    "option.lenses.antireflective": "Antireflektiv",
    "option.lenses.milky": "Milchglas",
    "option.lenses.shaded": "Getönt",
    "option.lenses.mirrored": "Verspiegelt",

    "copydialog.title": "Kopieren einer Appkonfiguration",
    "copydialog.stage.test": "Testing",
    "copydialog.stage.prod": "Live",
    "copydialog.description": "Bitte wählen Sie die App und die Stufe von der die Appkonfiguration kopiert werden soll..",
    "copydialog.button.title": "Copy&Edit",

    "duplicationdialog.title": "Duplizieren von {{NAME}}",
    "duplicationdialog.brand": "Marke",
    "duplicationdialog.category": "Kollektion",
    "duplicationdialog.description": "Wählen Sie bitte die App und Kollektion in die die Brille kopiert werden soll.",

    "errorTitle.dxf": "Fehler beim Lesen der dxf Daten:",

    "DeepLinkCreator.title": "Direktlink zum Modell „{{model}}‟ aus der Kollektion {{category}}:",

    "builds.title": "Einstellungen",
    "builds.brandsettings.orderNotifications.Title": "Wie sollen Bestellungen an Sie weiter geleitet werden?",
    "builds.brandsettings.orderNotifications.ViaEmail": "Via Email",
    "builds.brandsettings.orderNotifications.ViaHTTP": "Als API-Call",
    "builds.brandsettings.orderNotifications.orderCallbackURL": "Callback URL",
    "builds.brandsettings.orderNotifications.invalidURL": "Unvollständige URL Adresse",
    "builds.brandsettings.orderNotifications.orderCallbackAPIKey": "API Key",
    "builds.brandsettings.orderNotifications.orderEmailDescription": "Diese Email wird Kunden als Absender angezeigt, wenn Sie Bestellbestätigungen erhalten. Bitte nicht vergessen, dass neue Email-Adressen in AWS erst noch verifiziert werden müssen, bevor wir in Ihrem Namen Emails versenden können.",
    "builds.brandsettings.button": "Brand Settings",
    "builds.brandsettings.text": "Brand-weite Einstellungen vor allem im Backend benutzt.",
    "builds.targetsettings.button": "Target Settings",
    "builds.targetsettings.text": "Einstellungen für die Apps.",
    "builds.duplicateBrand.button": "Brand duplizieren",
    "builds.duplicateBrand.text": "Erstellt einen neuen Kunden/Brand basierend auf {{current}}",
    "settings.title": "Anpassen der {{name}} von <i>{{brand}}</i>:",

    "materialpreview.rotate": "Rotieren",
    "materialpreview.background": "Hintergr.",
    // GENERAL

    "greeting": "Hallo",

    "prompt.unsavedChanges": "Sie haben ungespeicherte Änderungen. Sind Sie sicher, dass Sie die Seite verlassen möchten?",

    "duplicate": "Duplizieren",
    "edit": "Bearbeiten",
    "delete": "Löschen",
    "cancel": "Abbrechen",
    "submit": "Abspeichern"
};